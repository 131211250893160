import React from 'react';
import styled from 'styled-components';
import Typography, { TypeVariant } from '../../shared/Typography';
import Image from 'next/image';

const Guarantee = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography
          variant={TypeVariant.header02}
          as="h2"
          phone={TypeVariant.subtitle01}
          color="var(--color-white)"
          textAlign="center"
        >
          100% Solin Guarantee
        </Typography>
      </HeaderWrapper>
      <SubHeaderWrapper>
        <Typography
          variant={TypeVariant.bodyLarge01}
          phone={TypeVariant.body01}
          as="p"
          color="var(--color-white)"
          textAlign="center"
        >
          Purchase access to any creator and we guarantee your satisfaction;
          otherwise, get your money back at any point within 30 days of
          purchase.
        </Typography>
      </SubHeaderWrapper>
      <ImageWrapper>
        <Image
          src="/images/marketing/solinguaranteestamp.png"
          layout="fill"
          objectFit="contain"
        />
      </ImageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1000px;
  padding: 0 32px;
  margin-left: auto;
  margin-right: auto;
`;

const HeaderWrapper = styled.div`
  padding-bottom: var(--spacing-16);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-bottom: var(--spacing-8);
  }
`;

const SubHeaderWrapper = styled.div`
  padding-bottom: var(--spacing-48);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-bottom: var(--spacing-32);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;

  width: 187px;
  height: 113px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    width: 144px;
    height: 87px;
  }
`;

export default Guarantee;
