import styled from 'styled-components';

export enum Size {
  spacing4 = 'var(--spacing-4)',
  spacing8 = 'var(--spacing-8)',
  spacing12 = 'var(--spacing-12)',
  spacing16 = 'var(--spacing-16)',
  spacing24 = 'var(--spacing-24)',
  spacing32 = 'var(--spacing-32)',
  spacing40 = 'var(--spacing-40)',
  spacing48 = 'var(--spacing-48)',
  spacing64 = 'var(--spacing-64)',
  spacing80 = 'var(--spacing-80)',
  spacing96 = 'var(--spacing-96)',
  spacing160 = 'var(--spacing-160)',
}

interface Props {
  axis?: 'vertical' | 'horizontal';
  color?: string;
  size: Size;
  showOutline?: boolean;
}

const getHeight = ({ axis, size }: Props) =>
  axis === 'horizontal' ? '1px' : size;

const getWidth = ({ axis, size }: Props) =>
  axis === 'vertical' ? '100%' : size;

const Spacer = styled.span<Props>`
  display: block;
  background: ${(p) => p.color};
  width: ${getWidth};
  min-width: ${getWidth};
  height: ${getHeight};
  min-height: ${getHeight};
  border: ${(p) =>
    p.showOutline ? 'dashed hsl(123deg 50% 71% / 0.1)' : undefined};
`;

export default Spacer;
