import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  overflow: hidden;
`;

export const Wrapper = styled.section`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1470px) {
    max-width: 1440px;
  }
`;

export const Title = styled.div`
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
  margin-left: 8.3333%;
  text-align: center;
  padding-bottom: 48px;
  color: var(--color-white);
`;

export const TitleRow = styled.div`
  margin-bottom: 8px;
`;

export const SubtitleRow = styled.div``;
