import CreatorSection from 'components/Marketing/CreatorSection';
import Banner from 'components/Marketing/Banner';
import Head from 'next/head';
import React from 'react';
import { Footer } from 'components/Footer/Footer';
import styled from 'styled-components';
import Header, { HeaderVariant } from 'shared/Header/Header';
import Spacer, { Size } from 'shared/Spacer';
import Gurantee from 'components/Marketing/Guarantee';

const Marketing = () => {
  return (
    <div>
      <Head>
        <title>Solin - The Home of Social Fitness</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          property="og:title"
          content="Solin - The Home of Social Fitness"
          key="title"
        />
        <meta
          property="og:description"
          content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
        />
        <meta
          property="og:image"
          content="https://www.solin.stream/images/ogImage.png"
        />
        <meta
          name="description"
          key="description"
          content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
        />
      </Head>
      <Container>
        <Header variant={HeaderVariant.dark} />

        <Banner />

        <Spacer size={Size.spacing160} />

        <CreatorSection />

        <CustomSpacer />

        <Gurantee />

        <Spacer size={Size.spacing96} />

        <Footer background="black" />
      </Container>
    </div>
  );
};

const Container = styled.div`
  background: #000;
`;

const CustomSpacer = styled.span`
  display: block;
  min-height: var(--spacing-160);
  height: var(--spacing-160);

  @media ${(p) => p.theme.queries.tabletAndDown} {
    min-height: var(--spacing-96);
    height: var(--spacing-96);
  }
`;

export default Marketing;
