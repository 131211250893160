import React from 'react';
import Button from '../../shared/Button';
import Typography, { TypeVariant } from '../../shared/Typography';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const MainCTA = () => {
  return (
    <div>
      <TitleWrapper
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Title>{'The Home\nof Social Fitness'}</Title>
      </TitleWrapper>
      <SubtitleWrapper
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Subtitle>{'Programs & challenges with the best creators'}</Subtitle>
      </SubtitleWrapper>
      <ButtonWrapper
        initial={{ x: '-100%' }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', duration: 0.75, delay: 1 }}
      >
        <Button variant="fill" size="large" link href="/create-account">
          Get Started
        </Button>
      </ButtonWrapper>
    </div>
  );
};

const TitleWrapper = styled(motion.div)`
  padding-bottom: var(--spacing-24);

  /* only doing this because it is a unique case where
  line height is not off our type scale, highly don't recommend doing this and wish I did not have to */
  & > h1 {
    line-height: var(--line-height-sm);
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-bottom: var(--spacing-12);
  }
`;

const Title = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant={TypeVariant.header02}
    phone={TypeVariant.subtitle01Bold}
    as="h1"
    color="var(--color-white)"
    preWrap
  >
    {children}
  </Typography>
);

const SubtitleWrapper = styled(motion.div)`
  padding-bottom: var(--spacing-32);
`;

const Subtitle = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant={TypeVariant.subtitle02}
    phone={TypeVariant.bodyLarge01Bold}
    as="h1"
    color="var(--color-gray-300)"
  >
    {children}
  </Typography>
);

const ButtonWrapper = styled(motion.div)`
  width: fit-content;
  padding-right: 105px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding-right: 64px;
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-right: 28px;
  }
`;

export default MainCTA;
