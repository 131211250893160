import Link from 'next/link';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Image from 'next/image';
import { buildImageUrl } from 'cloudinary-build-url';
import { RESIZE_TYPES } from '@cld-apis/utils';
import Text, { TypeVariant } from 'shared/Typography';

const movebounce = keyframes`
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

const RecordImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  bottom: -150px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(100%);
  transition: all 0.3s ease;
  overflow: hidden;
  padding: 0 10px;
  will-change: filter;
`;

const RecordImageWrapper = styled.div`
  width: 95%;
  position: relative;
  z-index: 1;
`;

const RecordImage = styled(Image)`
  width: 100%;
`;

const ImageFluid = styled(Image)`
  position: relative;
  max-width: 100%;
  height: auto;
  z-index: 2;
  border-radius: 16px;
  filter: grayscale(100%) brightness(0.5);
  transition: 700ms;
  will-change: filter;
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 150px;
  width: 100%;
  z-index: 2;
`;

const Item = styled.div`
  width: 100%;
  padding: 0 15px;
  position: relative;
  cursor: pointer;

  &:hover ${ImageFluid} {
    filter: unset;
  }

  &:hover ${RecordImageContainer} {
    animation: ${movebounce} 20s linear infinite;
    filter: unset;
  }

  @media (max-width: 1050px) {
    ${RecordImageContainer} {
      animation: ${movebounce} 20s linear infinite;
      filter: unset;
    }
    ${ImageFluid} {
      filter: unset;
    }
  }
`;

const BottomContainer = styled.article`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 45px;
  pointer-events: none;
  color: var(--color-white);
  z-index: 2;
`;

const CreatorName = styled.div`
  padding-bottom: 6px;
`;

interface Props {
  hero: string;
  spinner: string;
  name: string;
  description: string;
  page: string;
}

const CreatorSectionItem: React.FC<Props> = ({
  hero,
  spinner,
  name,
  description,
  page,
}) => {
  // width and height here is based on how large the image can get with its responsive sizing
  const heroSrc = buildImageUrl(hero, {
    transformations: {
      resize: {
        type: RESIZE_TYPES.SCALE,
        width: 1000,
        aspectRatio: '4:5',
      },
      quality: 100,
    },
  });
  const spinnerSrc = buildImageUrl(spinner, {
    transformations: {
      resize: {
        type: RESIZE_TYPES.SCALE,
        width: 400,
        aspectRatio: '1:1',
      },
    },
  });

  return (
    <Link href={`/${page}`} passHref>
      <a>
        <Item>
          <Container>
            <div style={{ zIndex: 2 }}>
              <ImageFluid
                src={heroSrc}
                layout="responsive"
                width={1}
                height={1.25}
              />
            </div>
            <BottomContainer>
              <CreatorName>
                <Text variant={TypeVariant.subtitle02} as="h3">
                  {name}
                </Text>
              </CreatorName>
              <Text variant={TypeVariant.bodyLarge01} as="p">
                {description}
              </Text>
            </BottomContainer>

            <RecordImageContainer>
              <RecordImageWrapper>
                <RecordImage
                  src={spinnerSrc}
                  layout="responsive"
                  width={1}
                  height={1}
                />
              </RecordImageWrapper>
            </RecordImageContainer>
          </Container>
        </Item>
      </a>
    </Link>
  );
};

export default CreatorSectionItem;
